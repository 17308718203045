<template>
	<div class="page-login">
		<div class="img"></div>
		<div>
			<el-row :gutter="20">
				<el-col :xs="{span: 20, offset: 2}" :sm="{span: 18, offset: 3}" :md="{span: 12, offset: 6}" :lg="{span: 6, offset: 9}" :xl="{span: 6, offset: 9}">
					<el-form :model="credentials" :rules="rules" ref="form">
						<el-form-item :label="$t('login.username')" prop="username">
							<el-input v-model="credentials.username" autofocus/>
						</el-form-item>
						<el-form-item :label="$t('login.password')" prop="password">
							<el-input type="password" v-model="credentials.password" @keyup.enter.native="login"/>
						</el-form-item>
						<el-form-item align="center">
							<el-button type="success" @click="login">
								{{ $t('login.login') }}
							</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import auth from '../services/auth';

export default {
	name: 'signin',
	data() {
		return {
			credentials: {
				username: '',
				password: ''
			},
			rules: {
				username: [{ required: true, trigger: 'blur' }],
				password: [{ required: true, trigger: 'blur' }]
			}
		};
	},
	mounted() {
		if (this.$store.getters.local.auth.isLoggedIn) {
			this.$store.commit('clearSession');
			this.$message({
				showClose: true,
				type: 'info',
				message: this.$t('login.logoutSuccess'),
				duration: 2000
			});
		}
		this.$utils('stopLoadingAfter', []);
	},
	methods: {
		login() {
			this.$refs.form.validate((valid) => {
				if (valid) {
					auth.login(this, this.credentials, true).then(() => {

					}).catch((error) => {
						this.$utils('handleError', error);
					});
				}
			});
		}
	}
};
</script>


<style scoped>
    .el-form {
        padding: 20px 10px 10px 10px;
        background-color: rgba(215, 215, 215, 0.51);
    }

    .img {
        background: url('../assets/login.jpg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0px;
    }
</style>
